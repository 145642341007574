<template>
    <div class="d-flex">
        <v-text-field
            v-bind="$attrs"
            v-on="$listeners"
            :rules="rules"
            class="toogleable-textfield"
        ></v-text-field>
        <v-btn 
            class="toogleable-button"
            depressed
            color="primary"
            @click="cycleIndex"
            :disabled="$attrs.disabled"
        >{{ toogleText }}</v-btn>
    </div>
</template>
<script>
export default {
  props: {
    toogleList: {
      type: Array,
      default: () => [
        { text: '%', value: 'percent' },
        { text: '฿', value: 'value' },
      ],
    },
    toogleValue: {
      type: [String, Number],
    },
    rules: {
      type: Array,
    },
  },
  computed: {
    toogleText() {
      return this.toogleList[this.toogleIndex].text
    },
    toogleIndex() {
      const index = this.toogleList.findIndex(data => data.value == this.toogleValue)

      if (!index) return 0

      return index
    },
  },
  methods: {
    cycleIndex() {
      const newIndex = this.toogleIndex + 1 >= this.toogleList.length ? 0 : this.toogleIndex + 1

      this.$emit('update:toogleValue', this.toogleList[newIndex].value)
    },
  },
}
</script>
<style scoped>
.toogleable-textfield {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toogleable-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-height: 40px;
  min-width: unset !important;
}
</style>